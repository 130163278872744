/*!
 * Castalytics GmbH (c) 2022-2024
 * Project: snipocc
 */

@use "helpers";
//region Theme Customizations
@import '@progress/kendo-theme-bootstrap/dist/bootstrap-main';
//endregion

@import "@progress/kendo-theme-bootstrap/dist/all";

html, body {
  height: 100%;
}

.k-notification-container {
  margin: 1rem;
}

.k-popup {
  overflow: clip;
}

.reactive-button-group {
  @include helpers.on-small-screens {
    & {
      width: 100%;
      flex-flow: row wrap;
      justify-content: center;

      & .k-button {
        width: 100%;
      }
    }
  }
}

.fl {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  position: relative;
  width: 2em;
  display: inline-block;
  height: unset;
  min-height: 0;
  aspect-ratio: 4 / 3;
}

.fl:before {
  content: " ";
}

.fl-de {
  background: linear-gradient(to bottom, black calc(100% / 3), #DD0000 calc(100% / 3), #DD0000 calc(100% / 3 * 2), #FFCE00 calc(100% / 3 * 2));
}

.fl-fr {
  background: linear-gradient(to right, #002395 calc(100% / 3), white calc(100% / 3), white calc(100% / 3 * 2), #ED2939 calc(100% / 3 * 2));
}

.fl-gb {
  background:
    linear-gradient(to bottom, transparent 40%, #CC142B 40%, #CC142B 60%, transparent 60%),
    linear-gradient(to right, transparent 45%, #CC142B 45%, #CC142B 55%, transparent 55%),
    linear-gradient(to bottom, transparent 35%, white 35%, white 65%, transparent 65%),
    linear-gradient(to right, transparent 40%, white 40%, white 60%, transparent 60%),
    linear-gradient(146deg, transparent 50%, #CC142B 50%, #CC142B 53%, transparent 53%) 10px -9px no-repeat,
    linear-gradient(146deg, transparent 50%, #CC142B 50%, #CC142B 53%, transparent 53%) -10px 7px no-repeat,
    linear-gradient(34deg, transparent 50%, #CC142B 50%, #CC142B 53%, transparent 53%) -15px -8px no-repeat,
    linear-gradient(34deg, transparent 50%, #CC142B 50%, #CC142B 53%, transparent 53%) 15px 10px no-repeat,
    linear-gradient(146deg, transparent 45%, white 45%, white 55%, transparent 55%),
    linear-gradient(34deg, transparent 45%, white 45%, white 55%, transparent 55%),
    linear-gradient(to right, #00247D,#00247D);
}