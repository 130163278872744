/*!
 * Castalytics GmbH (c) 2022-2024
 * Project: snipocc
 */

@use "variables" as v;

@mixin on-small-screens($min-width: v.$min-screen) {
  @media screen and (width <= $min-width) {
    @content
  }
}
